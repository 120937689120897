/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '@fontsource/roboto-condensed';
import '@fontsource/nunito-sans';

import 'tailwindcss/dist/base.min.css';
import './src/utils/globals.css';
